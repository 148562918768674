import { store, Model } from "hybrids";
import { get, post, remove } from "../utils/api";

import Comment from "./Comment";
import { Patron } from "./Broadcast";
import Payment from "./Payment";
import Sponsor from "./Sponsor";
import User from "./User";

const URL = "/artist_videos/:id";

const PREFIX_LENGTH = 7;

const groupsOrder = new Map<string, number>([
  ["Artist".slice(0, PREFIX_LENGTH), 0],
  ["Artists".slice(0, PREFIX_LENGTH), 0],
  ["Accompanist".slice(0, PREFIX_LENGTH), 1],
  ["Executive Producer".slice(0, PREFIX_LENGTH), 2],
  ["Curator".slice(0, PREFIX_LENGTH), 3],
  ["Venue".slice(0, PREFIX_LENGTH), 4],
  ["Organizer".slice(0, PREFIX_LENGTH), 5],
  ["Producer".slice(0, PREFIX_LENGTH), 6],
  ["Promoter".slice(0, PREFIX_LENGTH), 7],
  ["Sponsor".slice(0, PREFIX_LENGTH), 8],
  ["Host".slice(0, PREFIX_LENGTH), 9],
  ["Staff".slice(0, PREFIX_LENGTH), 10],
  ["Volunteer".slice(0, PREFIX_LENGTH), 11],
  ["Advisor".slice(0, PREFIX_LENGTH), 12],
  ["Instructur".slice(0, PREFIX_LENGTH), 13],
  ["Teacher".slice(0, PREFIX_LENGTH), 14],
  ["Director".slice(0, PREFIX_LENGTH), 15],
  ["Board of Directors".slice(0, PREFIX_LENGTH), 16],
  ["Artistic Director".slice(0, PREFIX_LENGTH), 17],
  ["Designer".slice(0, PREFIX_LENGTH), 18],
  ["Collaborator".slice(0, PREFIX_LENGTH), 19],
  ["Owner".slice(0, PREFIX_LENGTH), 20],
]);



interface CollaboratorsGroup {
  name: string;
  collaborators: User[];
}

interface ArtistVideo {
  id: string;
  name: string;
  description: string;
  src: string;
  url: string;
  iframely: string;
  muxDataApiKey: string;
  thumbnail: string;
  poster: string;
  collaboratorsGroups: CollaboratorsGroup[];
  sponsors: Sponsor[];
  owner?: User;
  payments: Payment[];
  users: User[];
  comments: Comment[];
  likedComments: Comment[];
  permissions: {
    canManageAllComments: boolean;
  };
  tags: string[];
  hasLike: boolean;

  // computed properties
  patrons: Patron[];
}

export const sortVideoCollaboratorsGroups = (collaboratorsGroups: CollaboratorsGroup[]): CollaboratorsGroup[] => {
  return [...collaboratorsGroups].sort((g1, g2) => {
    const g1Val = groupsOrder.get(g1.name.slice(0, PREFIX_LENGTH));
    const g2Val = groupsOrder.get(g2.name.slice(0, PREFIX_LENGTH));
    if (g1Val < g2Val) {
      return -1;
    }
    return 1;
  });
}

export async function removeComment(video: ArtistVideo, comment: Comment) {
  const set = new Set(video.comments);

  set.forEach((c) => {
    if (c === comment || c.parent === comment) {
      set.delete(c);
    }
  });

  store.set(video, { comments: [...set] });
  store.set(comment, null);

  await remove(`${URL}/comments/${comment.id}`, video.id);

  return null;
}

export async function toggleCommentLike(video: ArtistVideo, comment: Comment) {
  const set = new Set(video.likedComments);
  const hasComment = set.has(comment);

  set[hasComment ? "delete" : "add"](comment);
  store.set(video, { likedComments: [...set] });

  const {id, ...values}: Comment = await post(
    `${URL}/comments/${comment.id}/${hasComment ? "dislike" : "like"}`,
    null,
    video.id
  );

  values.context = {
    type: "video",
    identifier: video.id,
  }

  return store.set(comment, values);
}

export async function toggleVideoFavorite(video: ArtistVideo) {
  const { id, ...values }: ArtistVideo = await post(
      `${URL}/${video.hasLike ? "dislike" : "like"}`,
      null,
      video.id
  );
  return store.set(video, { hasLike: !video.hasLike });
}

const ArtistVideo: Model<ArtistVideo> = {
  id: true,
  name: "",
  description: "",
  src: "",
  url: "",
  iframely: "",
  muxDataApiKey: "",
  thumbnail: "",
  poster: "",
  collaboratorsGroups: [{
    name: "",
    collaborators: [User]
  }],
  collaborators: [User],
  sponsors: [Sponsor],
  owner: User,
  payments: [Payment],
  users: [User],
  comments: [Comment],
  likedComments: [Comment],
  permissions: {
    canManageAllComments: false,
  },
  tags: [""],
  hasLike: false,

  // computed properties
  patrons: ({ payments }) => {
    const acc = new Map<User, Patron>();

    payments
      .filter(({ user }) => store.ready(user))
      .forEach(({ user, amount }) => {
        const patron = { user, amount: (acc.get(user)?.amount ?? 0) + amount };

        acc.set(user, patron);
      });

    return [...acc].map(([_, patron]) => patron);
  },

  [store.connect]: {
    get: (id) => get("/artist_videos/:id", id),
    set: (_, values) => values,
    list: (query) => {
      if (query && typeof query === "object") {
        if (query.related) {
          if (query.tag) {
            return get("/artist_videos/:id/related", {
              id: query.id,
              tag_name: query.tag,
              page: query.page,
            });
          }

          return get("/artist_videos/:id/related", {
            id: query.id,
            page: query.page,
          });
        } else if (query.featured) {
          return get("/artist_videos/featured", {
            artist_id: query.artist_id,
            artist_type: query.artist_type,
            page: query.page,
          });
        } else if (query.tag) {
          return get("/artist_videos/tagged", {
            tag: query.tag,
            page: query.page,
          });
        } else if (query.liked) {
          return get("/artist_videos/liked", {
            page: query.page,
            user_id: query.user_id,
          });
        }
      }

      return get("/artist_videos", query);
    },
    offline: 1000 * 60 * 60 * 24 * 7, // 7 days
  },
};

export default ArtistVideo;
